



.project-card{
    padding: 1.6rem;
    box-shadow: 0 .2rem .5rem rgba(0,0,0,.5);
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    &__actions{
        display: flex;
    }

    &__tech{
        display: flex;
        padding: .4rem;
        margin-bottom: 0rem;
        transform: translateY(-50%);
        justify-content: center;
       
    }

    &__tech-bubble{
        margin-right: .4rem;
        padding: .3rem 1rem;
        color: #fff;
        font-size: 1.2rem;
        border-radius: 1rem;
        background: #555;
        box-shadow: 0 .1rem .1rem rgba(0,0,0,.8);
    }

    &__img-container{
        border: 1px solid #ddd;
        width: 100%;
        height: 16rem;
        background-position: center center;
        background-size: cover;

    }

    &__img{
        max-width: 100%;
        max-height: 100%;
    }

    &__title{
        font-size: 2rem;
        text-align: center;
        letter-spacing: .1rem;
        margin-bottom: 0rem;
        color: #000;
        padding: .5rem 0;
        background: #333;
        color: #fff;
    }

    &__description{
        margin: .4rem 0;
        margin-bottom: 2rem;
        font-size: 1.4rem;
        color: #353839;
        text-align: center;
    }

    &__btn{
        text-decoration: none;
        padding: .8rem 0;
        padding-left: .8rem;
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: none;
        border: 1px solid #aaa;
        transition: all .3s;
        position: relative;
        background: #5272bd;
        color: #fff;

        &:hover{
            background: #5272bdc4;
            box-shadow: inset 0 0 .3rem rgba(0,0,0,.3);
        }

        &:active{
            background: purple;
            color: #fff;
            fill: #fff;
        }

        svg{
            margin-right: 1rem;
        }
    }

    &__btn-github{
        flex: 1;
    }

    &__btn-website{
        flex: 1;

    }
}