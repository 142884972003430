

.animation{



    &--fadeInTop{
        animation: fadeInTop 1s forwards;
        animation-delay: 0s;
    }
}


@keyframes fadeInTop{
    from{
        opacity: 0;
        transform: translateY(-100px);
    }
    to{
        opacity: 1;
        transform: translate(0);
    }
}