.space-background{
    position: absolute;
    top: 0;
    left: 0;    
    width: 100%;
    height: 100%;

    &__canvas{
        width: 100%;
        height: 100%;
        background: #232323;
    }
}