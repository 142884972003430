@use "../abstracts/index.scss" as *;

.header{
    display: flex;
    justify-content: space-between;
    padding: .8rem 10vw;
    border-bottom: 1px solid #eee;
    box-shadow: 0 0 .4rem rgba(0,0,0,.3);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;
    background: #fff;
    align-items: center;

    @include respond(tab-port){
        padding: 1.4rem 10vw;
    }


    &__nav{
        display: flex;
        position: relative;
    }

    &__nav--open &__btn-bar--1{
        transform: translate(-50%, -50%) rotate(45deg);
    }


    &__nav--open &__btn-bar--2{
        opacity: 0;
    }

    &__nav--open &__btn-bar--3{
        transform: translate(-50%, -50%) rotate(-45deg);
    }
 
    &__nav--open &__nav-links{
        @include respond(tab-port){        
            clip-path: circle(2000px at 90% -5%);
        }
    }
    &__nav--open  &__nav-item{
        transform: translate(0, 0);
    }

    &__nav-item{
        margin: 0 .5rem;
        cursor: pointer;
        display: block;

        @include respond(tab-port){
            width: 60%;
            margin: 1.8rem 0;
            transition: all .5s;
            transition-delay: .2s;
            transform: translate(200px, 0);
        }
    }

    &__nav-item a{
        cursor: pointer;
        font-size: 1.4rem;
        height: 100%;
        display: block;
        text-decoration: none;
        display: flex;
        align-items: center;
        padding: 0 .5rem;

        @include respond(tab-port){
            font-size: 4rem;
            text-align: center;
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 1.6rem 0;
           
        }
    }

    &__menu-btn{
        background: #fff;
        border: none;
        cursor: pointer;
        z-index: 50;
        width: 5rem;
        height: 5rem;
        position: relative;
        display: none;

      
        padding: 1.4rem;
        border-radius: 50%;

        @include respond(tab-port){
            width: 6rem;
            height: 6rem;
        }

        &:hover{
            background: #eee;
        }

        @include respond(tab-port){        
            display: block;
        }
    }

    &__btn-bars{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        
    }

    &__nav-links{
     
        display: flex;
        list-style: none;
        z-index: 20;
        background: #fff;
        
        @include respond(tab-port){   
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: clip-path .5s ease-out;     
            flex-direction: column;
            align-items: center;
            justify-content: center;
            clip-path: circle(50px at 90% -5%);
        }
        
    }





    &__btn-bar{
        width: 3rem;
        height: 4px;
        border-radius: 2px;
        background: #333;
        display: flex;
        position: absolute;
        top: 50%;
        left: 50%;
        transition: all .3s;
        
      
        transform-origin: center center;

        &--1{
            transform: translate(-50%, calc(-50% - 8px));
        }

        &--2{
            transform: translate(-50%, -50%);
        }

        &--3{
            transform: translate(-50%, calc(-50% + 8px));
        }
    }
}