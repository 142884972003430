@use "../abstracts/index" as * ;

*,
*::after,
*::before{
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html{
    font-size: 62.5%; 

    @include respond(tab-land){
        font-size: 56.25%; //1rem = 8px, 9/16 = 50%
    }

    @include respond(tab-port){
        font-size: 50%; //1rem = 8px, 8/16 = 50%
    }

    @include respond(big-desktop){
        font-size: 75%; //1rem = 8px, 9/16 = 50%
    }
}

body{
    box-sizing: border-box;

    font-family:Verdana, Geneva, Tahoma, sans-serif ;
}

h1, h2, h3,h4{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif ;
}

html, body, .App, #root{
    width: 100%;
    min-height: 100%;
}

.section{
    width: 100%;
    padding: 5rem;
    position: relative;

    &__hero{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    &__projects{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        background: #eee;

        padding-bottom: 15rem;
        margin-bottom: -10rem;
        z-index: 2;
      
        clip-path: polygon(0% 0%, 0% calc(100% - 10rem),  100% 100%, 100% 0%);
    }

    &__contact{
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(29,22,147);
        background: linear-gradient(45deg, rgba(29,22,147,1) 0%, rgba(68,101,204,1) 46%, rgba(68,101,204,1) 46%, rgba(68,101,204,1) 48%, rgba(148,173,231,1) 100%);
    }


    &__footer{
        padding: 2rem
    }
}