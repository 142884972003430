@use "../abstracts/index.scss" as *;

.logo{
    z-index: 50;
    padding: 1rem;
    border: 1px solid #eee;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1 / 1;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.8rem;
    width: 4rem;
    height: 4rem;
    box-shadow: 0 .1rem .2rem rgba(0,0,0,.1);
    overflow: hidden;

    @include respond(tab-port){
        width: 5rem;
        height: 5rem;
        font-size: 2.4rem;
    }

    span{
        transform-origin: center top;
    }

    &__first-letter{
        margin-right: .1rem;
        transform: translate(-1px,1px);
        animation: firstLetteranimationIn .5s ease-in-out;
        
    }

    &__second-letter{
        transform: translate(1px, -2px);
        animation: secondLetterAnimationIn .5s ease-in-out;
     
    }

}

@keyframes secondLetterAnimationIn{
    from{
        transform: rotate(-65deg) translate(1px, -2px);
    }
    to{
        transform: rotate(0) translate(1px, -2px);
    }
}

@keyframes firstLetteranimationIn{
    from{
        transform: rotate(65deg) translate(-1px,1px);
    }
    to{
        transform: rotate(0) translate(-1px,1px);
    }
}