.hero-section{
    padding: 5rem;
    border-radius: 1rem;
    background: rgba(255,255,255, .9);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 5;
    box-shadow: 0 .2rem .2rem rgba(255,255,255,.8);

    &__overlay{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: transparent;
    }

    &__title{
        font-size: 5rem;
        font-weight: bold;
        padding-bottom: .6rem;
        margin-bottom: 1.2rem;
        border-bottom: 1px dotted #999;
        text-align: center;
    }

    &__subtitle{
        font-size: 2.4rem;
    }

    &__button{
        margin-top: 3rem;
        cursor: pointer;
        border: none;
        border-radius: .8rem;
        padding: 1rem 5rem;
        font-size: 2rem;
        box-shadow: 0 .2rem .5rem rgba(0,0,0,.5);
        background: #5272bd ;
        color: #fff;
        transition: all .3s;

        &:hover{
            box-shadow: 0 .2rem .3rem rgba(0,0,0,.4);
            color: #efefef;
            background: #445e9b ;
        }

        &:active{
            box-shadow: 0 .1rem .1rem rgba(0,0,0,.7);
            color: #efefef;
            background: #3f69c9 ;
        }

    }
}